<template>
  <div>
    <NavBar></NavBar>
    
    <router-view v-wechat-title="$route.meta.title" />

    <Footer></Footer>
  </div>
</template>

<script setup>
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import store from '@/store/index'
import { onMounted } from 'vue'
import { setRem } from '@/utils/rem'

onMounted(() => {
  store.dispatch('GET_WINDOW_RESIZE', true);
  setRem();
}) 
</script>

<style lang="scss">
/*每个页面公共scss */
@import "@/static/scss/flex.scss";
@import "@/static/scss/public.scss";
@import "@/static/scss/font.scss";
</style>
