<template>
    <div>
        <div class="nav-bar d-flex d-flex-items-center" :class="{
            float: swiperNum !== 1 && isWebFloat,
            static: navBarCurrent !== 0 || isOtherPage,
            mobile: swiperNum === 1 
        }" :style="{
            position: swiperNum === 1 ? 'absolute' : 'fixed'
        }">
            <div v-if="(navBarCurrent !== 0 || isOtherPage)">
                <img src="@/static/official-web/logo-white.png" class="logo" :class="{
                    large: swiperNum === 1
                }" />
            </div>

            <div v-else>
                <img src="@/static/official-web/logo.png" class="logo" :class="{
                    large: swiperNum === 1,
                }" v-if="!isWebFloat" />

                <img src="@/static/official-web/logo-white.png" class="logo" :class="{
                    large: swiperNum === 1,
                }" v-else />
            </div>

            <ul class="text-box d-flex" :style="{
                visibility: swiperNum === 1 ? 'hidden' : 'visible'
            }">
                <li @click="toLink(0)">首页</li>
                <li @click="toLink(1)">品牌故事</li>
                <li @click="toLink(2)">品牌理念</li>
                <li @click="toLink(3)">品牌历程</li>
                <li @click="toLink(4)">EWG</li>
                <li @click="toLink(5)">产品中心</li>
                <li @click="toLink(6)">品牌动态</li>
            </ul>

            <div class="icon-box" v-if="swiperNum === 1">
                <div @click="() => regionFlag = !regionFlag">
                    <img src="@/static/official-web/icon-classify.png" class="icon" v-if="!regionFlag">
                    <img src="@/static/official-web/close.png" class="icon" v-else>
                </div>
            </div>
        </div>

        <div class="region-box" v-show="swiperNum === 1" :class="{
            close: !regionFlag
        }">
            <ul>
                <li @click="toLink(0)">首页</li>
                <li @click="toLink(1)">品牌故事</li>
                <li @click="toLink(2)">品牌理念</li>
                <li @click="toLink(3)">品牌历程</li>
                <li @click="toLink(4)">EWG</li>
                <li @click="toLink(5)">产品中心</li>
                <li @click="toLink(6)">品牌动态</li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import store from '@/store/index'
import { useRouter } from 'vue-router'
import { setRem } from '@/utils/rem';

let regionFlag = ref(false);
let isWebFloat = ref(false);
let swiperNum = ref(3);
const route = useRouter();
let navBarCurrent = ref(0);

onMounted(() => {
    window.addEventListener('scroll', function () {
        var scrollDistance = window.scrollY;

        if (typeof scrollDistance === 'number' && scrollDistance >= 500) {
            isWebFloat.value = true;
        } else {
            isWebFloat.value = false;
        }
    })

    const path = route.currentRoute.value.fullPath;

    getPages(path)
    setSwiperNum();
    setRem()
})

const isOtherPage = computed(() => {
    return store.getters.isOtherPage
})

watch(() => (store.getters.resizeEvent), () => {
    setSwiperNum();
}, {
    deep: true
})

watch(() => (route.currentRoute.value.path), value => {
    getPages(value)
})

function getPages(path) {
    switch (path) {
        case '/':
            navBarCurrent.value = 0;
            break;
        case '/brandStory':
            navBarCurrent.value = 1;
            break;
        case '/brandConcept':
            navBarCurrent.value = 2;
            break;
        case '/brandHistory':
            navBarCurrent.value = 3;
            break;
        case '/brandEWG':
            navBarCurrent.value = 4;
            break;
        case '/productCenter':
            navBarCurrent.value = 5;
            break;
        case '/brandDynamics':
            navBarCurrent.value = 6;
            break;
    }
}

function toLink(flag) {
    switch (flag) {
        case 0:
            route.replace('/')
            break;
        case 1:
            route.replace('/brandStory')
            break;
        case 2:
            route.replace('/brandConcept')
            break;
        case 3:
            route.replace('/brandHistory')
            break;
        case 4:
            route.replace('/brandEWG')
            break;
        case 5:
            route.replace('/productCenter')
            break;
        case 6:
            route.replace('/brandDynamics')
            break;
    }

    navBarCurrent.value = flag;

    if(regionFlag.value) {
        regionFlag.value = false;
    }
}

function setSwiperNum(screenWidth = window.innerWidth) {
    console.log()
    if (screenWidth && typeof screenWidth === 'number') {
        if (screenWidth <= 500) {
            swiperNum.value = 1;
        } else if (screenWidth <= 750) {
            swiperNum.value = 2;
        } else {
            swiperNum.value = 3;
        }
    }
}
</script>

<style lang="scss">
.nav-bar {
    left: 0;
    top: 0;
    padding: 0 17.25rem 0 13.438rem;
    width: calc(120rem - 17.25rem - 13.438rem);
    height: 7rem;
    font-family: 'NavBarFont';
    transition: background-color 500ms;
    z-index: 999;

    &.static {
        position: relative !important;
        left: 0;
        top: 0;
        color: #FFFFFF;
        background-color: #0a5a35;
    }

    &.float {
        position: fixed !important;
        color: #FFFFFF;
        background-color: #0a5a35;
    }

    &.mobile {
        padding-top: 2.1rem;
        padding-bottom: 2.1rem;
    }

    .icon-box {
        position: absolute;
        right: 9rem;
        flex-shrink: 0;
    }

    .icon {
        width: 6rem;
        height: 6rem;
    }
}

.logo {
    width: 11.938rem;
    height: 1.75rem;

    &.large {
        width: 23.875rem;
        height: 3.5rem;
    }
}

.text-box {
    margin-left: 2.5rem;
    font-size: 1.875rem;

    li {
        margin-left: 5.375rem;
        white-space: nowrap;
        cursor: pointer;
    }
}

.region-box {
    position: absolute;
    top: 15rem;
    width: 100%;
    height: 88rem;
    transition: height 500ms;
    overflow: hidden;
    background-color: #eaeaea;
    z-index: 999;

    &.close {
        height: 0rem
    }

    ul {
        padding: 2rem 0;

        li {
            padding: 0 5rem;
            height: 12rem;
            line-height: 12rem;
            font-size: 4rem;
        }
    }
}
</style>