<template>
    <div class="container">
        <header class="header">
            <div class="tabbar-swiper-container">
                <swiper :slidesPerView="1" :loop="true" :speed="800" :autoplay="true"
                    @swiper="e => topSwiper = (e ? e : null)" @touchEnd="topSwiperTouchEnd">
                    <swiper-slide v-for="(item, index) in topAdvList" :key="index">
                        <img :src="baseUrl + item.image" alt="" />
                    </swiper-slide>
                </swiper>
            </div>
        </header>

        <section class="section">
            <!-- 品牌信息 -->
            <div class="brand-info d-flex d-flex-items-center">
                <div class="cover">
                    <img src="../../static/images/ls.png" />
                </div>

                <div class="info d-flex d-flex-end">
                    <div class="info-container">
                        <p class="title">EWG认证品牌</p>
                        <p class="context">更高标准</p>
                        <p class="context">成就更好品质</p>
                        <p class="context">发芽时光是全球第67个通过EWG认证的皮肤品牌*</p>
                        <p class="context">截至2024年4月 EWG官方检测</p>

                        <ul class="num-group d-flex">
                            <li class="num-scroll-box">
                                <div class="num">
                                    <count-up :end-val="webConfigVal.homeAdvDataProduct" :duration="2.5" :loop="1" :delay="2"></count-up>
                                </div>

                                <div class="label">产品</div>
                            </li>

                            <li class="num-scroll-box">
                                <div class="num">
                                    <count-up :end-val="webConfigVal.homeAdvDataEwg" :duration="2.5" :loop="1" :delay="2"></count-up>
                                </div>

                                <div class="label">EWG验证</div>
                            </li>

                            <li class="num-scroll-box">
                                <div class="num">
                                    <count-up :end-val="webConfigVal.homeAdvDataPassingRate" :duration="2.5" :loop="1" :delay="2" :decimalPlaces="3"></count-up>
                                </div>

                                <div class="label">通过率</div>
                            </li>
                        </ul>

                        <p class="hint">*2018年2月全球仅67个护肤品牌通过了EWG认证，发芽时光就是其中之一</p>
                    </div>
                </div>
            </div>

            <div class="section-title d-flex d-flex-center">
                <img src="../../static/official-web/section-title.png" alt="">
            </div>

            <div class="swiper-content center">
                <swiper :slidesPerView="swiperNum === 1 ? 2 : 3" :speed="800" :loop="true" :autoplay="true"
                    @slideChangeTransitionStart="firstSwiperChange" @swiper="setSwiperOne"
                    @touchEnd="swiperOneTouchEnd">
                    <swiper-slide v-for="(item, index) in topSwiperAdvList" :key="index"><img :class="{
                        large: swiperNum === 1
                    }" :src="baseUrl + item.image" alt="" /></swiper-slide>
                </swiper>

                <ul class="swiper-dot-group d-flex d-flex-center">
                    <li v-for="(item, index) in topSwiperAdvList" :key="index" :class="{
                        active: firstSwiperCurrent === index,
                        middle: swiperNum === 2,
                        smaller: swiperNum === 3
                    }" @click="swiperOneDotClick(index)"></li>
                </ul>

                <div class="arrow-group">
                    <div class="arrow-item left" @click="swiperOneStep(false)">&#xe619;</div>
                    <div class="arrow-item right" @click="swiperOneStep(true)">&#xe60c;</div>
                </div>
            </div>

            <div class="section-p" v-for="(item, index) in sectionAdvList" :key="index">
                <img :src="baseUrl + item.image" alt="">
            </div>

            <ul class="photo-view d-flex d-flex-between d-flex-wrap" :class="{
                mobile: swiperNum === 1
            }">
                <li v-for="(item, index) in footerAdvList" :key="index">
                    <img :src="baseUrl + item.image" alt="">
                </li>
            </ul>

            <!-- <div class="swiper-content" v-else>
                <swiper :slidesPerView="swiperNum" :loop="true" :speed="800" :autoplay="true"
                    @slideChangeTransitionStart="secondSwiperChange" @swiper="e => swiperTow = (e ? e : null)"
                    @touchEnd="swiperTwoTouchEnd">
                    <swiper-slide v-for="(item, index) in footerAdvList" :key="index">
                        <img class="large" :src="baseUrl + item.image" alt="" />
                    </swiper-slide>
                </swiper>

                <ul class="swiper-dot-group d-flex d-flex-center">
                    <li v-for="(item, index) in footerAdvList" :key="index" @click="swiperTwoDotClick(index)"
                        :class="{ active: secondSwiperCurrent === index }"></li>
                </ul>
            </div> -->

            <div class="d-flex store-label">
                <p>STORE</p>

                <p>SOCAIL</p>
            </div>
        </section>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import { onMounted, onBeforeMount, watch } from 'vue'
import { setRem } from '@/utils/rem'
import { ref, nextTick } from 'vue'
import store from '@/store/index'
import { baseUrl, getIndexConfig, getIndexAdv } from '@/api/api.js'
import CountUp from 'vue-countup-v3'

let swiperNum = ref(3);
let firstSwiperCurrent = ref(0);
let secondSwiperCurrent = ref(0);
SwiperCore.use([Autoplay, Pagination]);

// swiperInstance
let topSwiper = ref(null);
let swiperOne = ref(null);
let swiperTow = ref(null);

let topAdvList = ref([]);
let topSwiperAdvList = ref([]);
let sectionAdvList = ref([]);
let footerAdvList = ref([]);
let webConfigVal = ref({});

onBeforeMount(() => {
    setRem()
    setSwiperNum();
})

onMounted(() => {
    nextTick(() => {
        setRem()
        setSwiperNum();
        store.dispatch('GET_OTHER_PAGE_FLAG', false);
        getHomeAdvAPI({ adv_classify_id: 1 }, 1)
        getHomeAdvAPI({ adv_classify_id: 2 }, 2)
        getHomeAdvAPI({ adv_classify_id: 3 }, 3)
        getHomeAdvAPI({ adv_classify_id: 4 }, 4)
        getIndexConfigAPI()
    })
})

watch(() => (store.getters.resizeEvent), () => {
    setSwiperNum();
}, {
    deep: true
})

function getIndexConfigAPI() {
    getIndexConfig({ type: 1 }).then(res => {
        webConfigVal.value = res.data;
    })
}

function getHomeAdvAPI(data, flag = 1) {
    getIndexAdv(data).then(res => {
        if (flag === 1) {
            console.log(res.data)
            topAdvList.value = res.data;
        } else if (flag === 2) {
            topSwiperAdvList.value = res.data;
        } else if (flag === 3) {
            sectionAdvList.value = res.data;
        } else if (flag === 4) {
            footerAdvList.value = res.data;
        }
    })
}

function setSwiperNum(screenWidth = window.innerWidth) {
    if (screenWidth && typeof screenWidth === 'number') {
        if (screenWidth <= 500) {
            swiperNum.value = 1;
        } else if (screenWidth <= 750) {
            swiperNum.value = 2;
        } else {
            swiperNum.value = 3;
        }
    }
}

function firstSwiperChange(e) {
    if (typeof e === 'object' && typeof e.realIndex === 'number') {
        firstSwiperCurrent.value = e.realIndex;
    }
}

function secondSwiperChange(e) {
    if (typeof e === 'object' && typeof e.realIndex === 'number') {
        secondSwiperCurrent.value = e.realIndex;
    }
}

function setSwiperOne(e) {
    swiperOne.value = e;
}

function swiperOneStep(flag) {
    let current = firstSwiperCurrent.value;

    if (flag) {
        current++;
    } else {
        current--;
    }

    if (swiperOne.value) {
        swiperOne.value.slideToLoop(current, 100, true);
        swiperOne.value.autoplay.start();
    }
}

function swiperOneDotClick(e) {
    if (swiperOne.value) {
        swiperOne.value.slideToLoop && swiperOne.value.slideToLoop(e, 300, true);
        swiperOne.value.autoplay && swiperOne.value.autoplay.start();
    }
}

function swiperTwoDotClick(e) {
    if (swiperTow.value) {
        swiperTow.value.slideToLoop && swiperTow.value.slideToLoop(e, 300, true);
        swiperTow.value.autoplay && swiperTow.value.autoplay.start();
    }
}

function topSwiperTouchEnd() {
    if (topSwiper.value) {
        setTimeout(() => {
            topSwiper.value.autoplay && topSwiper.value.autoplay.start();
        }, 100)
    }
}

function swiperOneTouchEnd() {
    if (swiperOne.value) {
        setTimeout(() => {
            swiperOne.value.autoplay && swiperOne.value.autoplay.start();
        }, 100)
    }
}

function swiperTwoTouchEnd() {
    if (swiperTow.value) {
        setTimeout(() => {
            swiperTow.value.autoplay && swiperTow.value.autoplay.start();
        }, 100)
    }
}
</script>

<style lang="scss">
.container {
    .header {
        width: 120rem;
        height: 46.875rem;

        .tabbar-swiper-container {
            img {
                width: 120rem;
                height: 46.875rem;
            }
        }
    }

    .section {
        margin-top: 3rem;

        .brand-info {
            position: relative;
            height: 52.688rem;

            .cover {
                position: absolute;
                left: 0;
                top: 0;

                img {
                    width: 52.688rem;
                    height: 52.688rem;
                }
            }

            .info {
                width: 120rem;
                height: 40rem;
                background-color: #ecedee;

                .info-container {
                    width: 62rem;

                    .title {
                        margin-bottom: 1.2rem;
                        padding-top: 3rem;
                        font-size: 4rem;
                        font-family: NavBarFont;
                        color: #0a5a35;
                    }

                    .context {
                        margin-top: 1rem;
                        font-size: 1.8rem;
                        font-family: NavBarFont;
                        font-weight: bold;
                    }

                    .num-group {
                        margin-top: 2rem;

                        .num-scroll-box {
                            margin-right: 5rem;
                            text-align: center;

                            .num {
                                font-size: 5rem;
                                color: #0a5a35;
                            }

                            .label {
                                font-size: 1rem;
                                color: #0a5a35;
                            }
                        }
                    }

                    .hint {
                        margin-top: 2rem;
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .section-title {
            margin: 5rem 6.438rem;

            img {
                width: 40.063rem;
                height: 7rem;
            }
        }
    }

    .swiper-content {
        position: relative;
        margin-bottom: 8.625rem;
        width: 120rem;

        &.center {
            margin: 0 auto 4.625rem;
            width: 100rem;
        }

        img {
            width: 30.625rem;
            height: 43.063rem;
            transition: all 500ms;

            &.large {
                width: 41.4rem;
                height: 60rem;
            }

            &.middle {
                width: 50rem;
                height: 72.5rem;
            }
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
        }

        .arrow-group {
            font-family: 'SwiperArrow';
            font-size: 5rem;
            color: #999999;
            cursor: pointer;

            .left {
                position: absolute;
                top: calc(50% - 5rem);
                left: -8rem;

                &:hover {
                    color: #0078d4;
                }
            }

            .right {
                position: absolute;
                top: calc(50% - 5rem);
                right: -8rem;

                &:hover {
                    color: #0078d4;
                }
            }
        }

        .swiper-dot-group {
            margin-top: 7rem;

            li {
                flex-shrink: 0;
                margin: 0 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                transition: width 500ms;
                background-color: #d9d9d9;
                cursor: pointer;

                &.active {
                    background-color: #333333;
                }

                &.middle {
                    width: 0.8rem;
                    height: 0.8rem;
                    border-radius: 50%;
                }

                &.smaller {
                    width: .875rem;
                    height: .875rem;
                    border-radius: 50%;
                }
            }
        }
    }

    .section-p {
        margin-bottom: 3.125rem;

        &:last-child {
            margin: 0;
        }

        img {
            width: 120rem;
            height: 51.063rem;
        }
    }

    .photo-view {
        padding: 0 16.125rem;

        img {
            margin-bottom: 3.125rem;
            width: 39.5rem;
            height: 50.063rem;
        }

        &.mobile {
            padding: 0 5rem;

            img {
                margin-bottom: 3.125rem;
                width: 50rem;
                height: 64rem;
            }
        }
    }

    .store-label {
        padding-left: 30.688rem;
        padding-bottom: 1.875rem;

        p {
            font-size: 1.125rem;
            white-space: nowrap;

            &:first-child {
                width: 33rem;
            }
        }
    }
}
</style>