<template>
    <div>
        <div class="view-p1" v-if="p1Show" @touchmove.stop.prevent>
            <div class="mask" @click="() => p1Show = false" />
            <img src="http://faya.hntaobao.top/img/p1.png" alt="" class="view-p1-look">
        </div>

        <div class="view-p1" v-if="p2Show" @touchmove.stop.prevent>
            <div class="mask" @click="() => p2Show = false" />
            <img src="http://faya.hntaobao.top/img/p2.png" alt="" class="view-p2-look">
        </div>

        <div class="view-p1" v-if="p3Show" @touchmove.stop.prevent>
            <div class="mask" @click="() => p3Show = false" />
            <img src="http://faya.hntaobao.top/img/p3.png" alt="" class="view-p3-look">
        </div>

        <footer class="footer">
            <div class="footer-group d-flex">
                <ul>
                    <li><a href="https://buddate.tmall.com/shop/view_shop.htm?spm" target="_blank">天猫旗舰店</a></li>
                    <li><a href="https://buddateglobal.tmall.hk/shop/view_shop.htm?spm" target="_blank">海外旗舰店</a></li>
                    <li><a href="https://mall.jd.com/index-12988307.html?from=pc" target="_blank">京东美妆</a></li>
                    <li class="douyin">
                        <!-- <span>抖音旗舰店</span>
                    <img src="../../static/images/p1.png" alt="" class="img-p1" v-if="swiperNum !== 1"> -->
                        <a href="https://www.douyin.com/user/MS4wLjABAAAA0LHTfIQZzr-g1fHOv16T-xf-sSGDOPcoKLYXK--u8Sk"
                            target="_blank">抖音旗舰店</a>
                    </li>
                </ul>

                <ul>
                    <li><a href="https://www.xiaohongshu.com/user/profile/5c19d72a000000000702df4e?xhsshare=CopyLink&appuid=55eff4295894467448914dfe&apptime=1719888329&share_id=472a5bd21c4840d6b06e136319012a4f"
                            target="_blank">官方小红书账号 @BUDDATE发芽时光</a></li>
                    <li class="douyin-off">
                        <!-- <span>官方抖音号账号 @BUDDATE发芽时光</span>
                    <img src="../../static/images/p2.png" alt="" class="img-p2" v-if="swiperNum !== 1"> -->
                        <a href="https://www.douyin.com/user/MS4wLjABAAAA0LHTfIQZzr-g1fHOv16T-xf-sSGDOPcoKLYXK--u8Sk"
                            target="_blank">官方抖音号账号 @BUDDATE发芽时光</a>
                    </li>

                    <li><a href="https://weibo.com/u/5794806849" target="_blank">官方微博账号 @BUDDATE发芽时光</a></li>

                    <li class="weixin" @click="previeImg(3)">
                        <span>官方微信视频账号 @BUDDATE发芽时光</span>
                        <img src="@/static/images/p3.png" alt="" class="img-p3" v-if="swiperNum !== 1">
                    </li>
                </ul>
            </div>

            <div class="link-path">
                <span>Copyright © 发芽时光（上海）生物科技有限公司 | </span>
                <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备15044014号-1</a>
            </div>
        </footer>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import store from '@/store/index'

let swiperNum = ref(3);
let p1Show = ref(false);
let p2Show = ref(false);
let p3Show = ref(false);

onMounted(() => {
    setSwiperNum();
})

watch(() => (store.getters.resizeEvent), () => {
    setSwiperNum();
}, {
    deep: true
})

function previeImg(flag) {
    if (swiperNum.value === 1) {
        if (flag === 1) {
            p1Show.value = true;
        }

        if (flag === 2) {
            p2Show.value = true;
        }

        if (flag === 3) {
            p3Show.value = true;
        }
    }
}

function setSwiperNum(screenWidth = window.innerWidth) {
    if (screenWidth && typeof screenWidth === 'number') {
        if (screenWidth <= 500) {
            swiperNum.value = 1;
        } else if (screenWidth <= 750) {
            swiperNum.value = 2;
        } else {
            swiperNum.value = 3;
        }
    }
}
</script>

<style lang="scss">
.view-p1 {
    .mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 120rem;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 998;
    }

    .view-p1-look {
        position: fixed;
        left: calc(50% - 50rem);
        top: 0;
        margin-top: 10rem;
        width: 100rem;
        height: 150rem;
        z-index: 999;
    }

    .view-p2-look {
        position: fixed;
        left: calc(50% - 50rem);
        top: 0;
        margin-top: 10rem;
        width: 100rem;
        height: 150rem;
        z-index: 999;
    }

    .view-p3-look {
        position: fixed;
        left: calc(50% - 50rem);
        top: 0;
        margin-top: 10rem;
        width: 100rem;
        height: 140rem;
        z-index: 999;
    }
}

.footer {
    left: 0;
    bottom: 0;
    padding: 2.5rem 0;
    background-color: #ECEDEE;

    .footer-group {
        padding-left: 30.688rem;
    }

    ul {
        line-height: 3.75rem;
        font-size: 1.125rem;

        &:first-child {
            width: 33rem;
        }

        li {
            position: relative;
            cursor: pointer;

            a {
                text-decoration: none;
                color: #000000;
            }

            &.douyin {
                &:hover {
                    .img-p1 {
                        display: block;
                    }
                }
            }

            .img-p1 {
                display: none;
                position: absolute;
                left: 0;
                bottom: 5rem;
                width: 25.25rem;
                height: 37.625rem;
            }

            &.douyin-off {
                &:hover {
                    .img-p2 {
                        display: block;
                    }
                }
            }

            .img-p2 {
                display: none;
                position: absolute;
                left: 0;
                bottom: 5rem;
                width: 25.25rem;
                height: 37.875rem;
            }

            &.weixin {
                &:hover {
                    .img-p3 {
                        display: block;
                    }
                }
            }

            .img-p3 {
                display: none;
                position: absolute;
                left: 0;
                bottom: 5rem;
                width: 25.25rem;
                height: 35.35rem;
            }
        }
    }
}

.link-path {
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    color: #666666;

    a {
        color: #666666;
        text-decoration: none;
    }
}
</style>