import axios from "axios";

const Axios = axios.create({
    baseURL: '',
    timeout: 5000,
    headers: {

    }
})

// 请求拦截
Axios.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return error
    }
);

// 响应拦截
Axios.interceptors.request.use(
    response => {
        return response
    },
    error => {
        Promise.reject(error)
    }
)

export default Axios