import Axios from '@/config/requset-config.js'

export function Get(url, params = {}) {
    return new Promise((resolve, reject) => {
        Axios.get(url, {
            params
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export function Post(url, data = {}) {
    return new Promise((resolve, reject) => {
        Axios.post(url, {
            ...data
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export function Put(url, data = {}) {
    return new Promise((resolve, reject) => {
        Axios.put(url, {
            data
        })
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export function Delete(url) {
    return new Promise((resolve, reject) => {
        Axios.delete(url)
        .then(res => {
            resolve(res)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export default {
    Get,
    Post,
    Put,
    Delete
}