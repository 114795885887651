import { createRouter, createWebHashHistory } from 'vue-router'
import OfficialWeb from '../views/official-web/index.vue'

const routes = [
    {
        path: '/',
        name: 'officialWeb',
        component: OfficialWeb,
        meta: {
            title: '发芽时光'
        }
    },
    {
        path: '/brandStory',
        name: 'brandStory',
        component: () => import('../views/brand-story/index.vue'),
        meta: {
            title: '品牌故事'
        }
    },
    {
        path: '/brandConcept',
        name: 'brandConcept',
        component: () => import('../views/brand-concept/index.vue'),
        meta: {
            title: '品牌理念'
        }
    },
    {
        path: '/brandHistory',
        name: 'brandHistory',
        component: () => import('../views/brand-history/index.vue'),
        meta: {
            title: '品牌历程'
        }
    },
    {
        path: '/brandEWG',
        name: 'brandEWG',
        component: () => import('../views/brand-ewg/index.vue'),
        meta: {
            title: 'EWG'
        }
    },
    {
        path: '/productCenter',
        name: 'productCenter',
        component: () => import('../views/product-center/index.vue'),
        meta: {
            title: '产品中心'
        }
    },
    {
        path: '/brandDynamics',
        name: 'brandDynamics',
        component: () => import('../views/brand-dynamics/index.vue'),
        meta: {
            title: '品牌动态'
        }
    },
    {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import('../views/product-center/product-detail/index.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
