import { createStore } from 'vuex'
import { setRem } from '@/utils/rem'

export default createStore({
    state: {
        resizeEvent: {},
        navbarCurrent: 0,
        isOtherPage: false
    },
    getters: {
        resizeEvent: state => state.resizeEvent,
        isOtherPage: state => state.isOtherPage
    },
    mutations: {
        SET_WINDOW_RESIZE(state, event) {
            state.resizeEvent = event;
        },
        SET_NAVBAR_CURRENT(state, value) {
            state.navbarCurrent = value;
        },
        SET_OTHER_PAGE_FLAG(state, value) {
            state.isOtherPage = value;
        }
    },
    actions: {
        GET_WINDOW_RESIZE({ commit }) {
            window.onresize = function (e) {
                commit('SET_WINDOW_RESIZE', e)
                setRem()
            }
        },
        GET_NAVBAR_CURRENT({ commit }, value) {
            commit('SET_NAVBAR_CURRENT', value)
        },
        GET_OTHER_PAGE_FLAG({ commit }, value) {
            commit('SET_OTHER_PAGE_FLAG', value)
        }
    },
    modules: {
    }
})

