import request from "@/utils/request";

// export const baseUrl = 'http://www.fayashiguang.com'
export const baseUrl = 'https://www.buddate.cn'
// export const baseUrl = '/api'

export function getIndexConfig(data) {
    return request.Post(baseUrl + '/api/index/config', data)
}

export function getIndexAdv(data) {
    return request.Post(baseUrl + '/api/index/adv', data)
}

export function getProductInfo(data) {
    return request.Post(baseUrl + '/api/index/product', data)
}

export function getProductDetail(data) {
    return request.Post(baseUrl + '/api/index/productInfo', data)
}